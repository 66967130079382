class Category{
    constructor(heading,link){
        this.heading=heading;
        this.link=link;
    }
}

const data1=[
    new Category(
        "Treasure Hunt",
        "www.facebook.com"
    ),
    new Category(
        "Hide and seek",
        "www.facebooks.com"
    )
    ,new Category(
        "Antakshri",
        "www.faceasdasd"
    )
]

export default data1