class Category{
    constructor(heading,link){
        this.heading=heading;
        this.link=link;
    }
}

const data3=[
    new Category(
        "Academic event 1",
        "www.facebook.com"
    ),
    new Category(
        "Academic event 2",
        "www.facebooks.com"
    )
    ,new Category(
        "Academic event 3",
        "www.faceasdasd"
    )
]

export default data3;