import React from 'react'
import './StarnightBanner.css'
export default function StarnightBanner() {
  return (
    <div id="starnightbanner_container">
      <section>
        <h2>
            And the final closure with an amazing 
        </h2>
        <h1>
            STAR NIGHT!
        </h1>
      </section>
      
    </div>
  )
}
