class Category{
    constructor(heading,link){
        this.heading=heading
        this.link=link
    }
}

const data5=[
    new Category("formal event 1","xxxxxxxxxx"),
    new Category('formal event 2','XXXXXXXXXXXX')
]
export default data5