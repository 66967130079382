class culturalsData{
    constructor(heading,title,id,description,info,img){
        this.heading=heading;
        this.title=title;
        this.id=id;
        this.description=description
        this.info=info
        this.img=img
    }
}

const data=[
    new culturalsData(
        'Dancing',
        'Nach GANA',
        1,
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia culpa laudantium distinctio velit animi illum vitae eum voluptatem sit cum, veniam ducimus, cupiditate tempore pariatur hic dolorum rem ea fugit',
        '',
        'https://th.bing.com/th?id=OIP.Ao1vnVsXFKYZt1neqdGW3QHaLG&w=204&h=306&c=8&rs=1&qlt=90&o=6&dpr=1.3&pid=3.1&rm=2'
    ),
    new culturalsData(
        'Dancing',
        'Nach GANA',
        1,
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia culpa laudantium distinctio velit animi illum vitae eum voluptatem sit cum, veniam ducimus, cupiditate tempore pariatur hic dolorum rem ea fugit',
        '',
        'https://th.bing.com/th?id=OIP.Ao1vnVsXFKYZt1neqdGW3QHaLG&w=204&h=306&c=8&rs=1&qlt=90&o=6&dpr=1.3&pid=3.1&rm=2'
    ),
    new culturalsData(
        'Dancing',
        'Nach GANA',
        1,
        'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia culpa laudantium distinctio velit animi illum vitae eum voluptatem sit cum, veniam ducimus, cupiditate tempore pariatur hic dolorum rem ea fugit',
        '',
        'https://th.bing.com/th?id=OIP.Ao1vnVsXFKYZt1neqdGW3QHaLG&w=204&h=306&c=8&rs=1&qlt=90&o=6&dpr=1.3&pid=3.1&rm=2'
    )
]

export default data;